import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierTortue = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierTortue {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { eq: "Ballon Tortue" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Tortue Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Tortue Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/cat/cartier-tortue/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-tortue-header.png"
                alt="Pre-Owned Certified Used Cartier Tortue Header"
                aria-label="Used Cartier Tortue Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER TORTUE</h1>
            <h2>
              {' '}
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER TORTUE
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER TORTUE WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              Gray and Sons is a real watch and jewelry store located in Surfside, Miami across from
              the Bal Harbour Shops, with real watch and jewelry experts on-site, open six days a
              week, not a virtual store. Our customers say we are #1 Miami used watch buyer and
              seller proudly assisting for the past 42 years. We are sellers and buyers of pre-owned
              Cartier Tortue watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South
              Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more
              areas. Many people say, “ I want to sell my used Cartier Watches.” If this is you,
              come to Gray and Sons or go to &ensp;
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a> and we will buy
              your used Cartier Tortue Watch for cash. Gray and Sons Jewelers sells certified
              pre-owned Cartier Watches. Gray and Sons is a full service Cartier Tortue watch repair
              shop. We offer battery replacement for Cartier watches. All of our watches have been
              vetted by Master trained watchmakers.
              <br />
              <br />
              Luxury watch lovers will surely find something for themselves in the glorious Cartier
              watch collection. For those interested in timeless Cartier watches, we recommend
              Cartier Santos, Cartier Ballon Bleu, Cartier Pasha, Cartier Tank, or Cartier Tortue
              models. Purchasing a well-preserved, pre-owned Cartier Tortue Watch can be a great
              investment. Now, you can buy used Cartier Watches for the best price at Gray and Sons.
              <br />
              <br />
              Popular Cartier Tortue Models:
              <br />
              <br />
              <ul>
                <li>Cartier Tortue Ref. W1538351 Automatic in Yellow Gold </li>
                <li>Cartier Tortue Ref. W1580049 Automatic in Rose Gold</li>
                <li>Cartier Tortue Ref. 2688 Automatic in White Gold</li>
                <li>Cartier Tortue Ref. W008504 Manual in White Gold</li>
                <li>Cartier Tortue Ref. 2518D Automatic in Platinum</li>
                <li>Cartier Tortue Ref. 2498E Manual in Rose Gold</li>
                <li>Cartier Tortue Ref. W1543551 Chronograph Manual in Yellow Gold</li>
              </ul>
              <br />
              <br />
              From the Gray and Sons inventory comes two great Cartier’s Tortue watches. The first
              is an Unused Cartier Tortue "Tourbillon Platinum" 39mm Ref. W1545751. This Unused
              Cartier Tortue W1545751 watch is made out of Platinum on a Black Alligator strap with
              a Platinum Deploy buckle. This Cartier watch has a 39 x 37 mm case with a Tonneau
              caseback and Off White Roman Numeral dial. A second Cartier Tortue from the Gray and
              Sons inventory is a Cartier Tortue 34mm W518457. This watch is Certified preowned
              Dress Cartier Tortue W518457 watch made out of white gold on a 18k White Gold bracelet
              with a 18k White Gold Hidden buckle. This Cartier watch has a 34 x 33 mm case with a
              Tonneau caseback and White Roman Numeral dial. Circa: 2010s.
              <br />
              <br />
              Gray and Sons is known as a top luxury watch seller in the U.S. A vast inventory of
              pre-owned Cartier watches, fine Swiss watches, and a large, in-stock inventory of
              Rolex watches are some of the company’s main qualities. Looking for a certified
              authentic pre-owned Cartier watch? Buy the best priced watches, including diamond
              Cartier watches with diamond bezel, or Rolex, Omega, and Patek Philippe at Gray and
              Sons.
              <br />
              <br />
              Feel free to contact a representative for further assistance on{' '}
              <a href="/">www.grayandsons.com </a>
              or visit Gray &amp; Sons’ Showroom in Surfside, Miami. Meet one of our highly
              qualified in-house watchmakers and ask any questions about our process or about
              Cartier wristwatches and other luxury watches. Gray &amp; Sons is a professional
              luxury watch repair center offering full-service and Cartier watch battery
              replacement. We are independent Cartier watch dealers allowing for price flexibility
              and inventory control. Click and chat now with our specialists Rich and Vika via this{' '}
              <a href="/chat-with-rich">link </a>
              to make the best deal with our decision-makers. Gray &amp; Sons only offers watches
              that have been recently serviced, detailed, freshly lubricated, and timing adjusted.
              All available used Cartier watches from Gray &amp; Sons come with our exclusive
              in-house one-year warranty or a two-year warranty for modern Rolex watches. We offer
              certified authentic and certfied pre-owned watches.
              <br />
              <br />
              Are you thinking about where can I sell my watch or where can I sell my jewelry? Or
              looking for a Rolex buyer near me? You’re in the right place to sell your watch and
              sell your jewelry for cash today. Gray &amp; Sons is the top-rated place to sell your
              watch Miami and sell your jewelry Miami. Visit{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a> for the
              information you need. Sell Us Your Jewelry is the buying division of Gray &amp; Sons,
              the online jewelry buyer, and online watch buyer. As the best jewelry buyer and the
              best watch buyer, Sell Us Your Jewelry offers the highest prices in the shortest time
              for all Cartier models, other luxury watches, and luxury jewelry.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierTortue
